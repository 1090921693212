/*艺龙匹配弹窗 */ 
<template>
  <div>
    <el-dialog title="输入酒店名称" :visible.sync="dialogTableVisible">
      <div class="title">
        <el-input
          v-model="input"
          placeholder="输入酒店名称"
          prefix-icon="el-icon-search"
          size="small"
          width="160"
        ></el-input>
        <el-button type="primary" size="small" @click="search">搜索</el-button>
      </div>
      <el-table :data="gridData">
        <el-table-column
          property="hotelName"
          label="酒店名称"
          width="250"
        ></el-table-column>
        <el-table-column property="cityName" label="城市"></el-table-column>
        <el-table-column property="hotelPhone" label="电话"></el-table-column>
        <el-table-column property="operation" label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="yiLongMatchings(scope.row)"
              >匹配</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        style="float: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="count"
        :page-sizes="[15, 30, 45, 60]"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import {
  getYiLongMatchingList_api,
  manualMatching_api,
} from "../../../apis/hotel";
export default {
  props: {
    getMatchList: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      // 列表数据
      gridData: [
        {
          hotelName: "汉庭优佳酒店(西安土门店)",
          city: "西安",
          tel: "15884434786",
        },
      ],
      list: [],
      dialogTableVisible: false,
      //   搜索框值
      input: "",
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
    };
  },
  methods: {
    open(row) {
      console.log(row);
      this.list = row;
      this.input = row.ctripHotelName;
      this.getList();
      this.dialogTableVisible = true;
    },
    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.count = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList();
    },
    // 点击搜索酒店
    search() {
      this.getList();
    },
    // 获取匹配列表
    async getList() {
      let { code, data } = await getYiLongMatchingList_api({
        page: this.currentPage,
        rows: this.count,
        hotelName: this.input,
      });
      if (code == 0) {
        // console.log(data.records, "33333333");
        this.gridData = data.records;
        this.total = data.total;
      }
    },
    // 匹配酒店
    async yiLongMatchings(row) {
      console.log(row);
      let { code, data } = await manualMatching_api({
        hotelSonVestId: this.list.id,
        matching: 1,
        matchingHotelId: row.hotelId,
        matchingHotelName: row.hotelName,
      });
      if (code == 0) {
        this.$message({
          message: "匹配成功",
          type: "success",
        });
        this.getMatchList();
        this.dialogTableVisible = false;
      } else {
        this.$message({
          message: "匹配失败",
          type: "info",
        });
        this.getMatchList();
        this.dialogTableVisible = false;
      }
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
.title {
  display: flex;
}
/deep/.el-input {
  width: 160px;
  margin-right: 20px !important;
}
/deep/.el-input--small .el-input__inner {
  width: 160px;
  background: #f6f8fb !important;
}
/deep/.el-dialog {
  border-radius: 20px;
  padding: 30px 0;
  box-sizing: border-box;
}
/deep/.el-dialog__body {
  padding-top: 15px !important;
}
/deep/.el-table tr {
  background: #fafcff;
}
/deep/.el-pagination {
  margin-top: 10px;
}
.active {
  color: #ee4d52;
}
</style>