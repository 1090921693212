<template>
  <!-- 核心酒店 -->
  <div class="coreHotel">
  
    <!-- 酒店列表 -->
    <div class="list">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" align="center"> </el-table-column>
        <el-table-column
          prop="cityName"
          label="城市/酒店名称 备注-星级"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="statusList" align="center" label="售卖状态">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.statusList" :key="index">
              <div
                :class="
                  item.value == '1' ? 'changeColor' : 'changeColor active'
                "
              >
                {{ item.key }}:{{ item.value == "1" ? "在售" : "停售" }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="orderNum"
          align="center"
          label="订单量"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="nightNum" align="center" label="间夜量">
        </el-table-column>
        <el-table-column
          prop="preOverthrow"
          align="center"
          label="确认前推翻率"
        >
        </el-table-column>
        <el-table-column prop="achievement" align="center" label="业绩">
        </el-table-column>
        <el-table-column prop="productManager" align="center" label="产品经理">
        </el-table-column>
        <el-table-column prop="groupName" align="center" label="组别">
        </el-table-column>
        <el-table-column prop="onlineTime" align="center" label="上线时间">
        </el-table-column>
        <el-table-column prop="contacts" align="center" label="酒店联系人">
        </el-table-column>
        <el-table-column prop="contactsTel" align="center" label="酒店联系电话">
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="130"
          style="display: flex"
          align="center"
        >
          <template slot-scope="scope">
            <div class="tabBox">
              <!-- <div class="operation" @click="watchHome(scope.row)">房型</div> -->
              <div class="operation" @click="watchLog(scope.row)">日志</div>
              <!-- <div class="operation" @click="offline(scope.row)">删除</div>
              <div @click="reviseHotel(scope.row)" class="operation">修改</div>
              <div
                class="operation"
                v-if="role.includes('market_jl') == true"
                style="width: 140px"
                @click="openBaoliuDia(scope.row)"
              >
                保留天数
              </div> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        style="float: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="count"
        :page-sizes="[15, 30, 45, 60]"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <BatchMoveDialog
      :getDeletedList="getDeletedList"
      ref="batchMoveDialog"
    ></BatchMoveDialog>
    <HandoverHotel
      :getDeletedList="getDeletedList"
      ref="handoverHotel"
    ></HandoverHotel>
    <HotelSaleStatusDialog
      :getDeletedList="getDeletedList"
      ref="hotelSaleStatusDialog"
    ></HotelSaleStatusDialog>
    <SettingTime ref="settingTime"></SettingTime>

    <SettingStatus ref="settingStatus"></SettingStatus>
    <BaoliuDia ref="baoliudia"></BaoliuDia>
    <ModifyDiagLog :getDeletedList="getDeletedList" ref="modify" />
  </div>
</template>
<script>
import { getGroups_api } from "../../../apis/workbench";

import {
  deletedList_api,
  getGroupList_api,
  setBatchoffline_api,
} from "../../../apis/hotel";
import BatchMoveDialog from "./BatchDialog.vue";
import HandoverHotel from "./HandoverHotel.vue";
import SettingTime from "./SettingTime.vue";

import SettingStatus from "./SettingStatus.vue";
import HotelSaleStatusDialog from "./HotelSaleStatusDialog.vue";
import ModifyDiagLog from "./ModifyDiagLog.vue";
import BaoliuDia from "./BaoliuDia.vue";
//引入local
import local from "@/utils/local.js";
import { getRoleKey } from "@/utils/rolekey";
export default {
  components: {
    BatchMoveDialog,
    HandoverHotel,
    SettingTime,

    SettingStatus,
    HotelSaleStatusDialog,
    ModifyDiagLog,
    BaoliuDia,
  },
  // props:{
  //   parentsData:{
  //     default:'',
  //   }
  // },
  props: ["parentsData"],

  data() {
    return {
      role_hotel_core_move: getRoleKey("role_hotel_core_move"),
      role_hotel_core_handover: getRoleKey("role_hotel_core_handover"),
      role_hotel_core_setsales: getRoleKey("role_hotel_core_setsales"),

      //酒店查询表单
      formInline: {
        roleName: "", // 职位
        group: "", // 组别

        goupId: "",
        people: "",
      },
      // 职位

      ajaxVestId: "", // 接口请求vest的id
      ajaxGroupId: "", // 接口请求group的id
      ajaxPersonId: "", // 接口请求person的id
      groupOptions: [], // 组别选择
      personOptions: [], // 人员选择
      jobOptions: [
        { label: "全部", value: "" },
        { label: "产品", value: 0 },
        { label: "运营", value: 1 },
        { label: "售前", value: 2, disabled: true },
        { label: "售后", value: 3, disabled: true },
        { label: "二次确认", value: 4, disabled: true },
      ], // 职位选择
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      tableData: [], //酒店表格
      role: JSON.stringify(local.get("role")), //角色
      multipleSelection: [], // 选中的表格数据
      hotelSonIds: [],
      searchData: {
        type: "1",
        isSearch: "1",
        page: "",
        rows: "",
        roleName: "",
        goupId: "",
        people: "",
      },
      //parentsDataNew:this.parentsData
    };
  },

  created() {
    this.getDeletedList(); // 核心酒店列表功能函数调用
    console.log(this.role, "角色id");
  },
  // computed:{
  //    parentsDataNew:function(){
  //      return this.parentsData
  //    }
  // },
  methods: {
    getChooseDataFn() {
      let getChooseData = {
        hotelSonIds: this.hotelSonIds.join(","),
        roleName: this.formInline.roleName,
        goupId: this.groupOptions[this.formInline.group]
          ? this.groupOptions[this.formInline.group].keyy
          : "",
        people: this.formInline.people,
      };
      return getChooseData;
    },
    // 批量移动弹窗
    batchMoveFn() {
      if (this.multipleSelection.length >= 1) {
        this.$refs.batchMoveDialog.open(this.multipleSelection);
      } else {
        this.$message({
          type: "info",
          message: "请您至少选择一家酒店哦!",
        });
      }
    },
    // 交接酒店弹窗
    handOverHotelFn() {
      // if (this.multipleSelection.length == 1) {
      //   this.$refs.handoverHotel.open(this.multipleSelection);
      // } else if (this.multipleSelection.length > 1) {
      //   this.$message({
      //     type: "info",
      //     message: "一次性只能交接一家酒店哦!!",
      //   });
      // } else {
      //   this.$message({
      //     type: "info",
      //     message: "请您至少选择一家酒店哦!",
      //   });
      // }

      if (this.multipleSelection.length == 0) {
        this.$message({
          type: "info",
          message: "请您至少选择一家酒店哦!",
        });
      } else {
        this.$refs.handoverHotel.open(this.hotelSonIds.join(","));
      }
    },
    // 设置/恢复酒店售卖状态
    settingHotelSaleStatusFn() {
      if (this.multipleSelection.length >= 1) {
        this.$refs.hotelSaleStatusDialog.open(this.hotelSonIds, 1);
      } else {
        this.$message({
          type: "info",
          message: "请您至少选择一家酒店哦!",
        });
      }
    },
    // 查看房型
    watchHome(row) {
      //跳转房型管理
      //  console.log(row);
      local.set("roomPageHotelId", row.hotelId);
      this.$router.push({
        path: "/room",
        // query: {
        //   hotelId: row.hotelId,
        // },
      });
    },
    // 查看日志
    watchLog(row) {
      this.$router.push({
        path: "/roomloglist",
        query: {
          id: row.hotelId,
          hotelName: row.cityName,
        },
      });
    },
    // 修改
    reviseHotel(row) {
      // console.log(row)
      this.$refs.modify.open(row.hotelId);
    },
    openBaoliuDia(row) {
      this.$refs.baoliudia.open(row.hotelId);
    },
    // 下线
    offline(row) {
      this.$confirm(`是否要将${row.cityName}进行删除操作?`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.offlineHotelFn(row.hotelId); // 调用下线酒店的功能函数
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 下线酒店功能函数
    async offlineHotelFn(id) {
      let { code, data } = await setBatchoffline_api({
        hotelSonIds: id,
      });
      if (code == 0 || data == "下线操作成功！") {
        this.$message({
          type: "success",
          message: data,
        });
        this.getDeletedList(); // 重绘视图
      } else {
        this.$message({
          type: "info",
          message: data,
        });
      }
      //   console.log(data, code, "下线酒店");
    },
    //分页
    handleSizeChange(val) {
      this.count = val;
      this.currentPage = 1;
      this.getDeletedList(); // 核心酒店列表功能函数调用
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDeletedList(); // 核心酒店列表功能函数调用
    },
    //多选
    handleSelectionChange(val) {
      this.multipleSelection = val; // 选中的表格数据
      //  console.log(this.multipleSelection, "选中的表格数据");
      let hotelSonIds = [];
      if (val) {
        val.forEach((item) => {
          hotelSonIds.push(item.hotelId);
        });
        //hotelSonIds = hotelSonIds.join(",");
      } else {
        hotelSonIds = "";
      }
      this.hotelSonIds = hotelSonIds;
      this.$emit("getChoose", hotelSonIds);
    },
    // 获取组别
    async getGroups(val) {
      let { code, data } = await getGroups_api({
        positionType: val,
      });
      if (code == 0) {
        this.formInline.group = "";
        this.formInline.people = "";
        this.formInline.goupId = "";
        this.personOptions = [];
        this.groupOptions = data; // 后续可能更改格式
      }
    },
    // 获取组的信息(获取对应的人员列表)
    getPersons(val) {
      this.formInline.people = ""; // 人员为空
      this.personOptions = this.groupOptions[val].list;
      this.ajaxGroupId = this.groupOptions[val].keyy; // 组id
      this.formInline.goupId = this.groupOptions[val].keyy; // 组id
      // console.log(this.ajaxGroupId, "组id");
    },
    // 查询
    searchCo() {
     
      this.currentPage = 1;

      this.getDeletedList();
    },
    // 清空
    clear() {
      this.formInline.roleName = "";
      this.formInline.goupId = "";
      this.formInline.people = "";

      this.searchData.roleName = "";
      this.searchData.goupId = "";
      this.searchData.people = "";

      this.groupOptions = [];
      this.personOptions = [];
    },
    getCoreLyHotelListFna() {
     
      this.getDeletedList();
    },
    // 获取核心酒店列表功能函数
    async getDeletedList() {
      let searchDataa = JSON.parse(JSON.stringify(this.searchData));
      let searchDataf = Object.assign(
        searchDataa,
        this.formInline,
        this.parentsData
      );
      // this.$parent.formInline
      searchDataf.page = this.currentPage;
      searchDataf.rows = this.count;

      let { code, data } = await deletedList_api(
        searchDataf
       
      );
      if (code == 0) {
        this.tableData = data.records; // 赋值
        this.total = data.total;
        //  console.log(data, "获取核心酒店列表");
      }
    },
    getMultipleSelection(){
      return this.multipleSelection
    }
  },
};
</script>

<style lang="less" scoped>
.changeColor {
  color: #33b93f;
  font-size: 12px;
  &.active {
    color: #ee4d52;
  }
}
/deep/.demo-form-inline {
  background: transparent !important;
}
.nav {
  width: 100%;
  background: #fff;
  padding: 15px 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  .demo-form-inlines {
    height: 40px;
  }
}
.operation {
  color: #256ef1;
}
.tabBox {
 
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 60px;
  div {
    width: 70px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #b3d8ff;
    background: #ecf5ff;
    margin: 0px 4px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
  }
}
</style>