<template>
  <!-- 酒店管理 -->
  <div class="hotel">
    <!-- 酒店查询 -->
    <el-form
      :inline="true"
      label-width="auto"
      :model="formInline"
      class="demo-form-inline"
    >
      <el-form-item label="省份">
        <el-input
          size="mini"
          style="width: 120px"
          clearable
          v-model="formInline.provinceName"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item label="城市">
        <el-input
          size="mini"
          style="width: 120px"
          clearable
          v-model="formInline.cityName"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item label="酒店名称">
        <el-input
          size="mini"
          style="width: 160px"
          clearable
          v-model="formInline.ctripHotelName"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item label="酒店ID">
        <el-input
          size="mini"
          style="width: 160px"
          clearable
          v-model="formInline.ctripHotelId"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item label="星级">
        <el-input
          size="mini"
          style="width: 120px"
          clearable
          v-model="formInline.start"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item label="标签">
        <el-select
          size="mini"
          style="width: 120px"
          v-model="formInline.typeCore"
          placeholder="请选择"
        >
          <el-option
            v-for="item in option1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="携程售卖状态">
        <el-select
          size="mini"
          style="width: 120px"
          v-model="formInline.Ctrip"
          placeholder="请选择马甲"
        >
          <el-option
            v-for="item in option2"
            :key="item.id"
            :label="item.horseName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select
          size="mini"
          style="width: 120px"
          v-model="formInline.CtripStatus"
          placeholder="请选择"
        >
          <el-option
            v-for="item in channelOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="艺龙售卖状态">
        <el-select
          size="mini"
          style="width: 120px"
          v-model="formInline.SameCityStatus"
          placeholder="请选择"
        >
          <el-option
            v-for="item in channelOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="去哪儿售卖状态">
        <el-select
          size="mini"
          style="width: 120px"
          v-model="formInline.QunarStatus"
          placeholder="请选择"
        >
          <el-option
            v-for="item in channelOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="美团售卖状态">
        <el-select
          size="mini"
          style="width: 120px"
          v-model="formInline.MeiTuanStatus"
          placeholder="请选择"
        >
          <el-option
            v-for="item in channelOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="飞猪售卖状态">
        <el-select
          size="mini"
          style="width: 120px"
          v-model="formInline.FilPigStatus"
          placeholder="请选择"
        >
          <el-option
            v-for="item in channelOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="起止时间">
        <el-date-picker
          v-model="chooseDate"
          size="mini"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
          <!-- :format="yyyy-MM-dd" -->
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" @click="searchHotel" type="primary"
          >搜索</el-button
        >
        <el-button size="mini" @click="clean" type="primary" plain
          >清空</el-button
        >
        <el-button
          v-show="role_hotel_addhotel"
          size="mini"
          @click="addHotel"
          type="success"
          >新增酒店</el-button
        >
        <el-button size="mini" type="primary" v-show="role_hotel_export" plain>
          <a :href="Url" style="color: #256ef1" @click="orderExportTwo">导出</a>
        </el-button>

        <el-button
          style="margin-left: 10px"
          size="mini"
          type="primary"
          plain
          v-show="role_hotel_yilong_export"
        >
          <a
            :href="yiLongUrl"
            size="mini"
            type=""
            style="color: #256ef1"
            @click="orderExport"
            >艺龙导出</a
          >
        </el-button>
        <el-button style="margin-left: 10px" size="mini" type="primary" plain>
          <a
            :href="meituanUrl"
            size="mini"
            type=""
            style="color: #256ef1"
            @click="orderExportForMeiTuan"
            >美团导出</a
          >
        </el-button>
        <el-button style="margin-left: 10px" size="mini" type="primary" plain @click="openSameCity">
          刷新艺龙信息
        </el-button>
      </el-form-item>
    </el-form>
    <!-- 酒店分类 -->
    <div class="choose">
      <el-menu
        background-color="#256ef1"
        text-color="#D5D5D5"
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        active-text-color="#fff"
      >
        <el-menu-item index="1" v-show="role_hotel_core">核心酒店</el-menu-item>
        <el-menu-item index="2" v-show="role_hotel_gen">一般酒店</el-menu-item>
        <el-menu-item index="3" v-show="role_hotel_offline"
          >待下线酒店</el-menu-item
        >
        <el-menu-item index="4" v-show="role_hotel_develop"
          >待开发酒店</el-menu-item
        >
        <el-menu-item index="5" v-show="role_hotel_audit"
          >审核酒店<span style="color: red" v-show="examineCount > 0"
            >({{ examineCount }})</span
          ></el-menu-item
        >
        <el-menu-item index="6" v-show="role_hotel_match"
          >待匹配酒店</el-menu-item
        >
        <el-menu-item index="7" v-show="role_hotel_black">黑名单</el-menu-item>
        <el-menu-item index="8">删除酒店</el-menu-item>
      </el-menu>
    </div>
    <!-- 对应页面 -->
    <Corehotel
      ref="core"
      :parentsData="formInline"
      @getChoose="getChooseValue"
      v-if="index == '1'"
    ></Corehotel>
    <Generalhotel
      ref="gen"
      :parentsData="formInline"
      @getChoose="getChooseValue"
      v-if="index == '2'"
    ></Generalhotel>

    <Offlinehotel
      ref="offline"
      :parentsData="formInline"
      v-if="index == '3'"
    ></Offlinehotel>
    <Develophotel
      ref="develop"
      :parentsData="formInline"
      v-if="index == '4'"
    ></Develophotel>
    <Audithotel
      ref="audit"
      :parentsData="formInline"
      v-if="index == '5'"
    ></Audithotel>
    <Matchinghotel
      ref="match"
      :parentsData="formInline"
      v-if="index == '6'"
    ></Matchinghotel>
    <Blackhotel
      ref="blackHotel"
      :parentsData="formInline"
      v-if="index == '7'"
    ></Blackhotel>
    <Deletedhotel
      ref="deletedhotel"
      :parentsData="formInline"
      v-if="index == '8'"
    ></Deletedhotel>
    <Yilongupdia ref="yilongupdia">

    </Yilongupdia>



  </div>
</template>

<script>
import Corehotel from "../../components/dialog/hotel/Corehotel.vue";
import Generalhotel from "../../components/dialog/hotel/Generalhotel.vue";
import Offlinehotel from "../../components/dialog/hotel/Offlinehotel.vue";
import Develophotel from "../../components/dialog/hotel/Develophotel.vue";
import Audithotel from "../../components/dialog/hotel/Audithotel.vue";
import Matchinghotel from "../../components/dialog/hotel/Matchinghotel.vue";
import Blackhotel from "../../components/dialog/hotel/Blackhotel.vue";
import Deletedhotel from "../../components/dialog/hotel/Deletedhotel.vue";
import Yilongupdia from "../../components/dialog/hotel/Yilongupdia.vue";
import { getVest_api, getExamineCount_api } from "../../apis/hotel";
import { getRoleKey } from "../../utils/rolekey";
import local from "@/utils/local.js";

export default {
  components: {
    Corehotel,
    Generalhotel,
    Offlinehotel,
    Develophotel,
    Audithotel,
    Matchinghotel,
    Blackhotel,
    Deletedhotel,
    Yilongupdia
  },
  data() {
    return {
      role_hotel_addhotel: getRoleKey("role_hotel_addhotel"),
      role_hotel_export: getRoleKey("role_hotel_export"),
      role_hotel_yilong_export: getRoleKey("role_hotel_yilong_export"),
      role_hotel_core: getRoleKey("role_hotel_core"),
      role_hotel_gen: getRoleKey("role_hotel_gen"),
      role_hotel_offline: getRoleKey("role_hotel_offline"),
      role_hotel_develop: getRoleKey("role_hotel_develop"),
      role_hotel_audit: getRoleKey("role_hotel_audit"),
      role_hotel_match: getRoleKey("role_hotel_match"),
      role_hotel_black: getRoleKey("role_hotel_black"),
      role_service_appealrecord_export: getRoleKey(
        "role_service_appealrecord_export"
      ),
      //酒店查询表单
      formInline: {
        provinceName: "",
        cityName: "",
        ctripHotelName: "",
        start: "",
        typeCore: "",
        CtripStatus: "",
        Ctrip: "",
        SameCityStatus: "",
        QunarStatus: "",
        MeiTuanStatus: "",
        FilPigStatus: "",
        timeStart: "",
        timeEnd: "",

        ctripHotelId: "",
        timeStart: "",
        timeEnd: "",
        provinceName: "",
        cityName: "",
        ctripHotelName: "",
        start: "",
        Ctrip: "",
        CtripStatus: "",
        QunarStatus: "",
        MeiTuanStatus: "",
        FilPigStatus: "",
        SameCityStatus: "",
      },
      chooseDate: ["", ""],
      activeIndex: "1", //酒店分类
      index: "1", //酒店分类与组件对应展示的方式
      //标签选择
      option1: [
        {
          value: "0",
          label: "一般酒店",
        },
        {
          value: "1",
          label: "核心酒店",
        },
      ],
      // 携程马甲
      option2: [],
      channelOption: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "在售",
        },
        {
          value: "0",
          label: "下线",
        },
      ],
      yiLongUrl: "",
      hotelSonIds: "",
      Url: "",
      examineCount: "",
      timer: "",
      meituanUrl: "",
      chooseHotelList: [],
    };
  },
  created() {
    this.getVest();
    this.getExamineCount();
    this.timer = setInterval(() => {
      this.getExamineCount();
    }, 20000);
    if (this.$route.query.activeIndex) {
      this.activeIndex = this.$route.query.activeIndex;
      this.index = this.$route.query.activeIndex;
      this.searchHotel();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.timer) {
      //如果定时器还在运行 或者直接关闭，不用判断
      clearInterval(this.timer); //关闭
    }

    next();
  },

  methods: {
    getChooseValue(hotelSonIds) {
      this.hotelSonIds = hotelSonIds;
      //  console.log(hotelSonIds);
    },
    async getExamineCount() {
      let { code, data } = await getExamineCount_api();
      if (code == 0) {
        this.examineCount = parseInt(data);
      }
    },
    //新增酒店
    addHotel() {
      this.$router.push("/hotel/addhotel");
    },
    //酒店分类
    handleSelect(key, keyPath) {
      this.hotelSonIds = "";
      // console.log(key, keyPath);
      this.index = key;
    },
    handleSelect2(hotelName) {
      this.formInline.ctripHotelName = hotelName;

      this.activeIndex = "6";
      this.index = "6";

      // this.$refs.match.getMatchList(this.formInline);
      setTimeout(() => {
        this.searchHotel();
      }, 500);
    },
    //搜索列表中的酒店
    searchHotel() {
      this.formInline.timeStart = this.chooseDate[0];
      this.formInline.timeEnd = this.chooseDate[1];
      if (this.index == "1") {
        this.$refs.core.getCoreLyHotelListFna(this.formInline);
      } else if (this.index == "2") {
        this.$refs.gen.getGenList(this.formInline);
      } else if (this.index == "3") {
        this.$refs.offline.getOffLine(this.formInline);
      } else if (this.index == "4") {
        this.$refs.develop.getDevelopList(this.formInline);
      } else if (this.index == "5") {
        this.$refs.audit.getAudit(this.formInline);
      } else if (this.index == "6") {
        this.$refs.match.getMatchList(this.formInline);
      } else if (this.index == "7") {
        this.$refs.blackHotel.getBlackList(this.formInline);
      } else if (this.index == "8") {
        this.$refs.deletedhotel.getDeletedList(this.formInline);
      }
    },
    // 清空
    clean() {
      this.formInline = {};
      this.chooseDate = ["", ""];
      if (this.index == "1") {
        this.$refs.core.getCoreLyHotelListFna(this.formInline);
      } else if (this.index == "2") {
        this.$refs.gen.getGenList(this.formInline);
      } else if (this.index == "3") {
        this.$refs.offline.getOffLine(this.formInline);
      } else if (this.index == "4") {
        this.$refs.develop.getDevelopList(this.formInline);
      } else if (this.index == "5") {
        this.$refs.audit.getAudit(this.formInline);
      } else if (this.index == "6") {
        this.$refs.match.getMatchList(this.formInline);
      } else if (this.index == "7") {
        this.$refs.blackHotel.getBlackList(this.formInline);
      } else if (this.index == "8") {
        this.$refs.deletedhotel.getDeletedList(this.formInline);
      }
    },
    //获取携程马甲
    async getVest() {
      let { code, data } = await getVest_api({});
      if (code == 0) {
        this.option2 = data;
      }
    },

    orderExport() {
      let token = local.get("tk");
      // if(this.hotelSonIds){
      this.yiLongUrl =
        "http://47.108.228.93:8080/otaHotel/room/yilongExportExcel?" +
        "token=" +
        token +
        "&isMy=1" +
        "&hotelSonId=" +
        this.hotelSonIds;
      // }else{
      //    this.yiLongUrl=
      //    "http://47.108.228.93:8080/otaHotel/room/yilongExportExcel?"+
      //    "token="+token+
      //    "&isMy=1"
      // }
    },
    orderExportTwo() {
      let token = local.get("tk");
      if (this.index == 1) {
        let childData = this.$refs.core.getChooseDataFn();
        this.Url =
          "http://47.108.228.93:8080/otaHotel/hotelSonExcel/getCoreOrOffenList?" +
          "token=" +
          token +
          "&page=1&rows=999999" +
          "&isSearch=1&roleName=" +
          childData.roleName +
          "&goupId=" +
          childData.goupId +
          "&people=" +
          childData.people +
          "&provinceName=" +
          this.formInline.provinceName +
          "&cityName=" +
          this.formInline.cityName +
          "&ctripHotelName=" +
          this.formInline.ctripHotelName +
          "&start=" +
          this.formInline.start +
          "&Ctrip=" +
          this.formInline.Ctrip +
          "&CtripStatus=" +
          this.formInline.CtripStatus +
          "&QunarStatus=" +
          this.formInline.QunarStatus +
          "&MeiTuanStatus=" +
          this.formInline.MeiTuanStatus +
          "&FilPigStatus=" +
          this.formInline.FilPigStatus +
          "&SameCityStatus=" +
          this.formInline.SameCityStatus +
          "&type=1";
      } else if (this.index == 3) {
        let childData = this.$refs.offline.getChooseDataFn();
        this.Url =
          "http://47.108.228.93:8080/otaHotel/hotelSonExcel/tapeDownline?" +
          "token=" +
          token +
          "&page=1&rows=999999" +
          "&isSearch=1&roleName=" +
          childData.roleName +
          "&goupId=" +
          childData.goupId +
          "&people=" +
          childData.people +
          "&provinceName=" +
          this.formInline.provinceName +
          "&cityName=" +
          this.formInline.cityName +
          "&ctripHotelName=" +
          this.formInline.ctripHotelName +
          "&start=" +
          this.formInline.start +
          "&Ctrip=" +
          this.formInline.Ctrip +
          "&CtripStatus=" +
          this.formInline.CtripStatus +
          "&QunarStatus=" +
          this.formInline.QunarStatus +
          "&MeiTuanStatus=" +
          this.formInline.MeiTuanStatus +
          "&FilPigStatus=" +
          this.formInline.FilPigStatus +
          "&SameCityStatus=" +
          this.formInline.SameCityStatus +
          "&applyStatus=0";
      } else if (this.index == 4) {
        this.Url =
          "http://47.108.228.93:8080/otaHotel/hotelSonExcel/getHotelToBeDevelopedOrBlacklist?" +
          "token=" +
          token +
          "&page=1&rows=999999" +
          "&isSearch=1&roleName=&goupId=&people=" +
          "&provinceName=" +
          this.formInline.provinceName +
          "&cityName=" +
          this.formInline.cityName +
          "&hotelName=" +
          this.formInline.ctripHotelName +
          "&starRating=" +
          this.formInline.start +
          "&typeCore=" +
          this.formInline.typeCore;
        ("&type=1");
      } else if (this.index == 7) {
        this.Url =
          "http://47.108.228.93:8080/otaHotel/hotelSonExcel/getHotelToBeDevelopedOrBlacklist?" +
          "token=" +
          token +
          "&page=1&rows=999999" +
          "&isSearch=1&roleName=&goupId=&people=" +
          "&provinceName=" +
          this.formInline.provinceName +
          "&cityName=" +
          this.formInline.cityName +
          "&hotelName=" +
          this.formInline.ctripHotelName +
          "&starRating=" +
          this.formInline.start +
          "&typeCore=" +
          this.formInline.typeCore;
        ("&type=2");
      } else if (this.index == 5) {
        let childData = this.$refs.offline.getChooseDataFn();
        this.Url =
          "http://47.108.228.93:8080/otaHotel/hotelSonExcel/getListExamine?" +
          "token=" +
          token +
          "&page=1&rows=999999" +
          "&isSearch=1&roleName=" +
          childData.roleName +
          "&goupId=" +
          childData.goupId +
          "&people=" +
          childData.people +
          "&provinceName=" +
          this.formInline.provinceName +
          "&cityName=" +
          this.formInline.cityName +
          "&ctripHotelName=" +
          this.formInline.ctripHotelName +
          "&start=" +
          this.formInline.start +
          "&Ctrip=" +
          this.formInline.Ctrip +
          "&CtripStatus=" +
          this.formInline.CtripStatus +
          "&QunarStatus=" +
          this.formInline.QunarStatus +
          "&MeiTuanStatus=" +
          this.formInline.MeiTuanStatus +
          "&FilPigStatus=" +
          this.formInline.FilPigStatus +
          "&SameCityStatus=" +
          this.formInline.SameCityStatus +
          "&typeCore=" +
          this.formInline.typeCore +
          "&examineType=" +
          childData.examineType;
      } else if (this.index == 6) {
        let childData = this.$refs.match.getChooseDataFn();
        this.Url =
          "http://47.108.228.93:8080/otaHotel/hotelSonExcel/getList?" +
          "token=" +
          token +
          "&page=1&rows=999999" +
          "&isSearch=1&roleName=" +
          childData.roleName +
          "&goupId=" +
          childData.goupId +
          "&people=" +
          childData.people +
          "&provinceName=" +
          this.formInline.provinceName +
          "&cityName=" +
          this.formInline.cityName +
          "&ctripHotelName=" +
          this.formInline.ctripHotelName +
          "&start=" +
          this.formInline.start +
          "&Ctrip=" +
          this.formInline.Ctrip +
          "&CtripStatus=" +
          this.formInline.CtripStatus +
          "&QunarStatus=" +
          this.formInline.QunarStatus +
          "&MeiTuanStatus=" +
          this.formInline.MeiTuanStatus +
          "&FilPigStatus=" +
          this.formInline.FilPigStatus +
          "&SameCityStatus=" +
          this.formInline.SameCityStatus +
          "&typeCore=" +
          this.formInline.typeCore +
          "&matching=" +
          childData.matching;
      } else if (this.index == 2) {
        let childData = this.$refs.gen.getChooseDataFn();
        this.Url =
          "http://47.108.228.93:8080/otaHotel/hotelSonExcel/getCoreOrOffenList?" +
          "token=" +
          token +
          "&page=1&rows=999999" +
          "&isSearch=1&roleName=" +
          childData.roleName +
          "&goupId=" +
          childData.goupId +
          "&people=" +
          childData.people +
          "&provinceName=" +
          this.formInline.provinceName +
          "&cityName=" +
          this.formInline.cityName +
          "&ctripHotelName=" +
          this.formInline.ctripHotelName +
          "&start=" +
          this.formInline.start +
          "&Ctrip=" +
          this.formInline.Ctrip +
          "&CtripStatus=" +
          this.formInline.CtripStatus +
          "&QunarStatus=" +
          this.formInline.QunarStatus +
          "&MeiTuanStatus=" +
          this.formInline.MeiTuanStatus +
          "&FilPigStatus=" +
          this.formInline.FilPigStatus +
          "&SameCityStatus=" +
          this.formInline.SameCityStatus +
          "&type=0";
      }
    },
    orderExportForMeiTuan() {
      if (this.index == "1") {
        this.chooseHotelList = this.$refs.core.getMultipleSelection();
      } else if (this.index == "2") {
        this.chooseHotelList = this.$refs.gen.getMultipleSelection();
      } else if (this.index == "3") {
        this.chooseHotelList = this.$refs.offline.getMultipleSelection();
      } else if (this.index == "5") {
        this.chooseHotelList = this.$refs.audit.getMultipleSelection();
      } else if (this.index == "8") {
        this.chooseHotelList = this.$refs.deletedhotel.getMultipleSelection();
      } else {
        this.chooseHotelList = [];
      }

      if (this.chooseHotelList.length > 1) {
        this.$message.warning("美团导出只能选择一个酒店");
        this.meituanUrl = "javascript:void(0)";
      } else {
        if (this.chooseDate[0] == "" || this.chooseDate[1] == "") {
          this.$message.warning("请选择起止日期");
          this.meituanUrl = "javascript:void(0)";
        } else {
          console.log("满足时间了", this.chooseDate);
          let token = local.get("tk");

          if (this.chooseHotelList.length == 0) {
            this.meituanUrl =
              "http://47.108.228.93:8080/otaHotel/hotelSonExcel/meituanExcel?" +
              "token=" +
              token +
              "&timeStart=" +
              this.chooseDate[0] +
              "&timeEnd=" +
              this.chooseDate[1];
          } else if (this.chooseHotelList.length == 1) {
            let hotelId = this.chooseHotelList[0].hotelId;
            console.log("满足一个了",this.chooseHotelList)
           
            this.meituanUrl =
              "http://47.108.228.93:8080/otaHotel/hotelSonExcel/meituanExcel?" +
              "token=" +
              token +
              "&timeStart=" +
              this.chooseDate[0] +
              "&timeEnd=" +
              this.chooseDate[1] +
              "&hotelId=" +
              hotelId;
          }
        }
      }
    },
    beforeDestroy() {
      if (this.timer) {
        //如果定时器还在运行 或者直接关闭，不用判断
        clearInterval(this.timer); //关闭
      }
    },
    openSameCity(){
      this.$refs.yilongupdia.open()
    }
  },
};
</script>

<style lang="less" scoped>
.hotel {
  /deep/.demo-form-inline {
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 12px 20px;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .el-form-item {
      margin: 0 10px;
    }
  }
  .choose {
    width: 100%;
    height: 60px;
    margin-top: 40px;
    border-radius: 10px 10px 0px 0px;
    .el-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      border-radius: 10px 10px 0px 0px;
    }
  }
}
</style>